<template>
  <v-card flat>
    <v-card-text class="py-1 px-12">
      <v-row ref="top">
        <v-col cols="12" class="py-0 px-12">
          <v-card
            flat
            :min-height="`${$vuetify.breakpoint.height - 120}px`"
            style="max-width: 1280px; margin: 0 auto"
            v-for="n in length"
            :key="n"
            :data-page="n"
            class="py-1"
            v-intersect="{
              handler: onIntersect,
              options: {
                threshold: [0, 0.25, 0.5, 0.75, 1.0],
              },
            }"
          >
            <v-img
              width="80vw"
              style="margin: 0"
              contain
              :eager="true"
              :src="imagePath(n, false)"
              :class="showMe ? 'show-me' : ''"
              :disabled-lazy-src="imagePath(n, true)"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiRecord } from "@mdi/js";

export default {
  props: {
    name: {
      type: String,
      default: "Crysvita",
    },
    length: {
      type: Number,
      default: 22,
    },
    visible: Boolean,
  },
  data: () => ({
    page: 1,
    pageinationIcon: mdiRecord,
    showMe: false
  }),
  computed: {
    transformWidth() {
      return (
        this.$vuetify.breakpoint.thresholds[this.$vuetify.breakpoint.name] ||
        1920
      );
    },
  },
  watch: {
    visible() {
      this.$nextTick(() => {
        const el = this.$refs.top;
        el.scrollIntoView();
      });
    },
    page: {
      handler: "track",
    },
  },
  methods: {
    track() {
      this.$emit("view-page", `Page ${this.page}`);
    },
    imagePath(number, lazy) {
      const pageNum = number > 9 || this.length < 10 ? number : "0" + number;
      const width = lazy ? 100 : Math.floor(this.transformWidth / 1.5);
      const transform = `?nf_resize=fit&w=${width}`;
      return `img/${this.name}/${this.name}_Page_${pageNum}.png${transform}`;
    },
    onIntersect(entries, observer) {
      if (entries[0].intersectionRatio >= 0.25) {
        this.page = entries[0].target.dataset.page;
      }
    },
    showMeToggle() {
      this.showMe = !this.showMe;
      setTimeout(this.showMeToggle, 500);
    }
  },
  mounted() {
    this.showMeToggle();
  }
};
</script>

<style>
.show-me .v-image__image {
  z-index: 2 !important;
}
</style>
